<template>
  <div class="profile">
    <div class="container" v-if="product.id">
      <div class="profile__title">
        <h3><router-link :to="{ name: 'ProductList' }">{{$t('product.management')}}</router-link> / <span>{{$t('product.details')}}</span></h3>
        <router-link :to="`${productId}-${storeId}/edit`" class="btn btn--green mg-l-20">{{$t('global.edit')}}</router-link>
      </div>
      <div class="box mg-b-10">
        <div class="title-bold mg-b-20">{{$t('product.product_details')}}</div>

        <div class="title-gray mg-b-5">{{$t('product.type')}}</div>
        <div class="text-dark">{{$t(productType[product.type])}}</div>
        <div class="flex align-start justify-between mg-b-15 mg-t-15">
          <div class="flex-1">
            <checkbox :checked="product.one_time_purchase_only" readOnly="true" id="one_time_purchase_only">{{$t('product.one_time_purchase_only')}}</checkbox>
          </div>
        </div>

        <div v-if="product.type === 2">
          <div class="title-gray mg-b-5 mg-t-15">{{$t('product.promo_type')}}</div>
          <div class="text-dark">{{$t(promoTypes[product.promotion_type])}}</div>
        </div>

        <div class="title-gray mg-b-5 mg-t-15">{{$t('product.number')}}</div>
        <div class="text-dark">{{product.system_id}}</div>

        <div class="title-gray mg-b-5 mg-t-15">{{$t('product.name')}}</div>
        <div class="text-dark">{{product.name}}</div>

        <div class="title-gray mg-b-5 mg-t-15">{{$t('product.name_c')}}</div>
        <div class="text-dark">{{product.name_c}}</div>

        <div class="title-gray mg-b-5 mg-t-15">{{$t('product.original_price')}}</div>
        <div class="text-dark">{{$filters.formatPrice(product.original_price)}}</div>

        <div class="title-gray mg-b-5 mg-t-15" v-if="product.type !== 2 && product.type !== 3">{{$t('product.price')}}</div>
        <div class="text-dark" v-if="product.type !== 2 && product.type !== 3">{{$filters.formatPrice(product.sell_price)}}</div>

        <div v-if="product.type !== 1 && product.type !== 4">

          <div class="flex mg-t-15 mg-b-15 align-start justify-start">

            <div class="flex-50" v-if="product.type === 3">
              <div class="title-gray mg-b-5">{{$t('product.negotiated_price')}}</div>
              <div class="text-dark">{{product.negotiated_price ? $filters.formatPrice(product.negotiated_price) : '-'}}</div>
            </div>
          </div>

          <div class="flex mg-t-15 mg-b-15 align-start justify-start">
            <div class="flex-50" v-if="product.type === 2">
              <div class="title-gray">{{$t('product.promo_price')}}</div>
              <div class="text-dark">{{$filters.formatPrice(product.promotion_price)}}</div>
            </div>
            <div class="flex-50" v-if="product.type === 3">
              <div class="title-gray">{{$t('product.points')}}</div>
              <div class="text-dark">{{product.points}}</div>
            </div>
          </div>
          <div class="flex mg-t-15 mg-b-15 align-start justify-start">
            <div class="flex-50">
              <div class="title-gray">{{$t('product.sales_start')}}</div>
              <div class="text-dark">{{product.sale_starts_at ? $filters.formatDateOnly(product.sale_starts_at) : '-'}}</div>
            </div>
            <div class="flex-50">
              <div class="title-gray">{{$t('product.sales_end')}}</div>
              <div class="text-dark">{{product.sale_ends_at ? $filters.formatDateOnly(product.sale_ends_at) : '-'}}</div>
            </div>
          </div>
          <div class="flex mg-t-15 mg-b-15 align-start justify-start">
            <div class="flex-50">
              <div class="title-gray">{{$t('product.redemption_start')}}</div>
              <div class="text-dark">{{product.redemption_starts_at ? $filters.formatDateOnly(product.redemption_starts_at) : '-'}}</div>
            </div>
            <div class="flex-50">
              <div class="title-gray">{{$t('product.redemption_end')}}</div>
              <div class="text-dark">{{product.redemption_ends_at ? $filters.formatDateOnly(product.redemption_ends_at) : '-'}}</div>
            </div>
          </div>
        </div>

        <div class="title-gray mg-b-5 mg-t-15">{{$t('product.description')}}</div>
        <div class="text-dark" v-html="product.description.replace(/(?:\r\n|\r|\n)/g, '<br>')"></div>

        <div class="title-gray mg-b-5 mg-t-15">{{$t('product.description_c')}}</div>
        <div class="text-dark" v-html="product.description_c.replace(/(?:\r\n|\r|\n)/g, '<br>')"></div>

        <div class="mg-t-20 mg-b-20">
          <img :src="product.avatar" class="uploaded-img"/>
        </div>

        <div class="title-gray mg-b-5">{{$t('product.disclaimer')}}</div>
        <div class="text-dark" v-html="product.disclaimer.replace(/(?:\r\n|\r|\n)/g, '<br>')"></div>

        <div class="title-gray mg-t-15 mg-b-5" v-if="product.terms">{{$t('product.terms')}}</div>
        <a target="_blank" :href="product.terms" class="flex align-center justify-start pointer" v-if="product.terms">
          <img src="/images/icons/folder.png"/>
          <span class="red mg-l-10 underline">{{$t('product.view')}}</span>
        </a>
      </div>

      <div class="box mg-b-10">
        <div class="title-gray mg-b-5">{{$t('product.merchant_edit_date')}}</div>
        <div class="text-dark">{{product.merchant_updated_at ? $filters.formatTimestamp(product.merchant_updated_at) : '-'}}</div>
        <div class="title-gray mg-t-15 mg-b-5">{{$t('product.merchant_edit_email')}}</div>
        <div class="text-dark">{{product.merchant_updated_by ? product.merchant_updated_by.email : '-'}}</div>
        <div class="title-gray mg-t-15 mg-b-5">{{$t('product.admin_approve_date')}}</div>
        <div class="text-dark">{{product.approved_at ? $filters.formatTimestamp(product.approved_at) : '-'}}</div>
        <div class="title-gray mg-t-15 mg-b-5">{{$t('product.admin_approve_email')}}</div>
        <div class="text-dark">{{product.approved_by ? product.approved_by.email : '-'}}</div>
        <div class="title-gray mg-t-15 mg-b-5">{{$t('product.status')}}</div>
        <div class="text-dark">{{$t(productStatus[product.status])}}</div>
      </div>
    </div>

    <div class="box preview" v-if="product.id">
      <div class="flex justify-between align-center title-bold mg-b-20">{{$t('product.preview')}} <lang-switch :language="prevLang" @langSelect="changeLang"/></div>

      <div class="title-gray mg-b-20">{{$t('product.page_list')}}</div>

      <PreviewBig :options="previewOptions"/>

      <div class="title-gray mg-b-20 mg-t-20">{{$t('product.grid_list')}}</div>

      <PreviewGrid :options="previewOptions"/>

      <div class="title-gray mg-b-20 mg-t-20">{{$t('product.list')}}</div>

      <PreviewList :options="previewOptions"/>
    </div>
    <Loading v-if="isLoading"/>
  </div>
</template>

<script>

import PreviewBig from '@/components/previews/previewBig'
import PreviewGrid from '@/components/previews/previewGrid'
import Checkbox from '@/components/checkbox/checkbox'
import PreviewList from '@/components/previews/previewList'
import { Product } from '@/api/api'
import { promoTypes, productStatus, productType } from '@/config/config'
import LangSwitch from '@/components/langSwitch/langSwitch'
import Loading from '@/components/loading/Loading'
export default {
  name: 'OrderDetails',
  components: {
    Checkbox,
    Loading,
    PreviewList,
    PreviewGrid,
    PreviewBig,
    LangSwitch
  },
  data () {
    return {
      storeId: 0,
      productId: 0,
      prevLang: 'en',
      product: {
        store: {},
        updated_by: {},
        approved_by: {}
      },
      isLoading: true
    }
  },
  mounted () {
    if (this.$route.params.id) {
      const ids = this.$route.params.id.split('-')

      if (ids.length === 2) {
        this.storeId = ids[1]
        this.productId = ids[0]

        this.fetchProduct()
      }
    }
  },
  computed: {
    productType () {
      return productType
    },
    promoTypes () {
      return promoTypes
    },
    productStatus () {
      return productStatus
    },
    previewOptions () {
      return {
        title: this.prevLang === 'en' ? this.product.name : this.product.name_c,
        avatar: this.product.avatar,
        description: this.prevLang === 'en' ? this.product.description : this.product.description_c,
        price: this.product.type === 2 ? this.product.promotion_price : this.product.sell_price,
        price_original: this.product.original_price,
        hasPoints: this.product.type === 3,
        points: this.product.points,
        disclaimer: this.product.disclaimer,
        hasPromo: this.product.type === 2,
        promotion_price: this.product.promotion_price,
        promotion_rate: this.product.promotion_rate,
        promotion_type: this.product.promotion_type,
        merchant_name: this.prevLang === 'en'
          ? this.product.store.company + ' - ' + this.product.store.district.name
          : this.product.store.company_c + ' - ' + this.product.store.district.name_c
      }
    }
  },
  methods: {
    changeLang (val) {
      this.prevLang = val
    },
    async fetchProduct () {
      this.isLoading = true

      try {
        const resp = await Product.getOne(this.storeId, this.productId)

        this.product = {
          ...resp.data
        }
      } catch (err) {
        this.$toast.error(this.$t('product.load_fail'))
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
  @import "style.scss";
</style>
